import React, { useEffect, useState } from "react";
import Hero from "../../sections/Hero/index";
import InfoCard from "../../components/InfoCard";
import AOS from "aos";
import logocri from "../../assets/3dLogoCRIRev.png";

import carrerAtCRI from "../../assets/carrerAtCri.jpeg";
import Button from "../../components/Button";
import { Link, useNavigate } from "react-router-dom";
import Section from "../../components/Section";
import TeamSection from "./Section/TeamSection";

import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { teamMembers, articlePaper, infoCardData } from "../../Data/data";
import FormatDateToText from "../../Constant/FormatDateToText";

import Loading from "../../components/Loading";
import useFetchApiGet from "../../Hooks/useFetchApiGet";
import ListArticle from "../../components/ListArticle";

export default function CRIComponent() {
    const [isLoading, setIsLoading] = useState(true);
    const [showFullText, setShowFullText] = useState(false);
    const navigate = useNavigate();
    const { data: listArticle, setData: setListArticle, fetchApiGet: fetchApiGetArticle } = useFetchApiGet();

    const paragraphs = [
        "Since its inception as pioneer company in Reliability in January 2014, CRI has been at the forefront of advancing the Reliability industry in Indonesia and beyond. With a commitment to excellence and innovation, we have successfully completed over 120 projects, serving a diverse range of clients in Indonesia as well as in Malaysia, Thailand, and Qatar",
        "Our extensive portfolio showcases our expertise in delivering high-quality solutions tailored to meet the unique needs of each client. By specializing in areas such as Failure Modes and Effects Analysis (FMEA), Root Cause Failure Analysis (RCFA), and Reliability-Centered Maintenance (RCM), we have significantly enhanced the operational efficiency and safety standards of numerous organizations.",
        "CRI’s contributions extend beyond project completion. We are dedicated to fostering a culture of continuous improvement and knowledge sharing within the industry. Our initiatives in improving the visibility of Maintenance & Reliability Data and implementing comprehensive Equipment/Machinery Healthiness programs have set new benchmarks for reliability and safety.",
        "In addition, CRI has developed a web-based, customizable Computerized Maintenance Management System (CMMS) known as IMEX. This innovative platform includes a wide range of assessment modules such as Asset Register, Spare Part Management, Equipment Criticality Analysis (ECA), Safety Environment Critical Element (SECE), Reliability-Centered Maintenance (RCM), Life Data Analysis (LDA), Risk-Based Inspection (RBI), Reliability Block Diagram (RBD), Corrosion Management, Integrity Operating Window (IOW), and Inspection Testing & Preventive Maintenance (ITPM). These features make CRI IMEX a one-stop solution for our clients’ business needs.",
        "Through our strategic partnerships and unwavering commitment to quality, CRI has established itself as a trusted leader in the Reliability industry. Our work not only supports the operational success of our clients but also contributes to the overall advancement of industry standards in the region.",
    ];

    const getReadTime = (summary) => {
        const plainText = summary
            .replace(/<[^>]+>/g, " ")
            .replace(/\s+/g, " ")
            .trim();
        const wordCount = plainText.split(" ").length;
        const wordsPerMinute = 225;
        const timeInMinutes = Math.ceil(wordCount / wordsPerMinute);
        return `${timeInMinutes} min read`;
    };

    const onGetArticle = async () => {
        try {
            const response = await fetchApiGetArticle(`/article/list/1/100/all`);
            if (response.status === 200) {
                const currentDate = new Date();

                const filteredAndSortedData = response.data.data
                    .map((item) => ({
                        ...item,
                        dateText: FormatDateToText(item.date),
                        timeRead: getReadTime(item.summary),
                        parsedDate: new Date(item.date),
                    }))
                    .filter((item) => item.parsedDate.getTime() <= currentDate.getTime())
                    .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

                setListArticle(filteredAndSortedData);
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    const toggleFullText = () => {
        setShowFullText(!showFullText);
    };

    const handleReadMoreArticle = (article) => {
        navigate(`/ourInsight/article/${article.id}`, { state: { article } });
    };

    useEffect(() => {
        AOS.init({
            duration: 800,
            once: true,
            offset: 100,
            easing: "ease-in-out",
        });
    }, []);

    useEffect(() => {
        const isFirstVisit = localStorage.getItem("firstVisit");
        const loadingTime = isFirstVisit ? 0 : 5000;
        const timer = setTimeout(() => {
            setIsLoading(false);
            localStorage.setItem("firstVisit", "false");
        }, loadingTime);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        onGetArticle();
    }, []);

    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="w-full h-full overflow-hidden">
                    <div
                        className="xl:h-screen lg:h-[100vh] md:h-[55vh] max-md:h-screen w-full flex justify-center items-center bg-background"
                        data-aos="fade-down"
                    >
                        <div className=" absolute xl:top-0 lg:-top-10 md:-top-52  lg:h-4/6 md:h-3/6 h-screen max-md:hidden w-full bg-gradient-to-b from-[#073067] to-[#1E6BCF]"></div>
                        <Hero className={" md:px-12 xl:h-[72vh] lg:h-[65vh] md:h-[35vh] h-[55vh] max-md:h-full w-full max-w-[1920px]  "} />
                    </div>
                    <div className="h-full flex justify-center items-end text-center md:py-10 py-6 xl:px-32 px-5 bg-background">
                        <div className="space-y-10 max-w-[1920px]">
                            {/* <div className="bg-main drop-shadow-sm w-44 mx-auto text-white rounded-full px-5 py-3 md:text-xl" data-aos="zoom-in-down"> */}
                            {/* <div className="text-main rounded-full px-5 py-3 md:text-xl" data-aos="zoom-in-down">
                                CRI's Journey
                            </div> */}
                            <h1 className="xl:text-4xl lg:text-3xl md:text-2xl text-3xl font-semibold md:px-10 leading-tight text" data-aos="fade-in">
                                CRI's Role in the Reliability or Asset Management Industry in Indonesia
                            </h1>
                            <div
                                className={`xl:text-xl lg:text-lg md:text-base text-sm md:px-10 font-normal space-y-4 text-justify transition-all duration-300 text-[#585858] ${
                                    showFullText ? "fade-enter fade-enter-active" : "fade-exit fade-exit-active"
                                }`}
                            >
                                {showFullText
                                    ? paragraphs.map((para, index) => (
                                          <p data-aos="fade-down" key={index}>
                                              {para}
                                          </p>
                                      ))
                                    : paragraphs.slice(0, 2).map((para, index) => (
                                          <p key={index} data-aos="fade-down">
                                              {para}
                                          </p>
                                      ))}
                            </div>
                            <div className="flex justify-center">
                                <button onClick={toggleFullText} className="flex items-center space-x-3  px-4 py-2 rounded-lg">
                                    <span>{showFullText ? "Read Less" : "Read More"}</span>
                                    {showFullText ? (
                                        <FaArrowUp className="duration-300 text-[#585858]/80" />
                                    ) : (
                                        <FaArrowDown className="duration-300" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                    <Section className={"bg-gradient-to-t from-[#09346E] to to-[#1857AB] lg:py-32 md:py-20 "} containerClassName="h-full w-full">
                        <div className="relative h-full flex flex-col justify-center items-start gap-10 xl:px-32 md:px-14 px-5 max-md:py-20  w-full max-w-[1920px]">
                            <h1 className="xl:text-5xl text-4xl font-bold text-white max-lg:text-center w-full" data-aos="fade-right">
                                Our Achievements
                            </h1>
                            <div className="w-full md:flex lg:justify-start md:justify-center grid justify-center items-center md:space-x-10 gap-5 ">
                                {infoCardData.map((item, index) => (
                                    <div key={index}>
                                        <InfoCard className="xl:w-52 xl:h-56 w-40 h-40 " data-aos="zoom-in-up">
                                            <div className="xl:text-6xl lg:text-5xl md:text-5xl text-5xl  font-bold" data-aos="zoom-in">
                                                {item.title}
                                            </div>
                                            <div className="xl:text-xl lg:text-lg md:text-base text-lg" data-aos="zoom-in">
                                                {item.subtitle}
                                            </div>
                                            <div className="xl:text-lg lg:text-base md:text-sm text-base font-light" data-aos="zoom-in">
                                                {item.description}
                                            </div>
                                        </InfoCard>
                                    </div>
                                ))}
                            </div>
                            <div className="absolute -right-10 -bottom-[70px] drop-shadow-lg shadow-black" data-aos="fade-left">
                                <img className="w-[400px] h-[400px] max-lg:hidden" src={logocri} alt="" />
                            </div>
                        </div>
                    </Section>

                    {/* {teamMembers && (
                        <Section
                            className=" xl:px-32 xl:py-20 lg:py-16 md:py-14 md:px-14 px-5 max-md:py-20 w-full h-full bg-background"
                            containerClassName="w-full justify-center item-center md:space-y-20 space-y-3"
                        >
                            <div className="h-full">
                                <TeamSection teamMembers={teamMembers} />
                            </div>
                        </Section>
                    )} */}

                    <Section className="bg-[#fefefe] w-full xl:px-36 md:px-10 lg:py-20 py-10" containerClassName="h-full w-full">
                        <div className="xl:space-y-20 md:space-y-10 max-md:space-y-4 w-full flex flex-wrap justify-center items-center">
                            <div className="h-1/4 text-lg font-bold md:space-y-5 max-md:space-y-4 md:text-[42px] px-5 md:px-0 w-full">
                                <div className="">
                                    <div data-aos="fade-down" className="leading-tight xl:text-4xl lg:text-3xl md:text-2xl text-3xl">
                                        <h1>
                                            Keep updated with the latest trends and information in <span className="text-[#67B7FD]">Asset</span>
                                        </h1>
                                        <h1 className="text-[#67B7FD]">Management Industries</h1>
                                    </div>
                                </div>
                                <p className="xl:text-xl lg:text-lg md:text-base text-sm font-normal text-[#9E9E9E]" data-aos="zoom-in">
                                    We serve the best insight for your industries and learn more about asset management solutions.
                                </p>
                            </div>
                            <div className="w-full">
                                {listArticle.length === 0 ? (
                                    <div className="grid grid-cols-3">
                                        <div
                                            className="text-center text-gray-400 text-xl font-medium h-[27rem] flex justify-center items-center animate-pulse duration-700 ease-in col-span-3"
                                            data-aos="fade-up"
                                        >
                                            Keep updated with the latest trends and information in Asset Management Industries
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <ListArticle article={listArticle} handleReadMoreArticle={handleReadMoreArticle} showButton={true} />
                                    </>
                                )}
                            </div>
                        </div>
                    </Section>
                    <Section className="bg-gradient-to-tr from-[#0D294F] from-30% to-[#0E4691] to-90% xl:px-36 lg:px-10 px-5 xl:py-40  py-20 ">
                        <div className="md:flex max-md:grid justify-center items-start xl:space-x-10 lg:space-x-6 md:space-x-10 space-x-3 max-md:space-y-10 md:px-0 md:py-0  h-full">
                            <div className="w-1/2" data-aos="fade-right">
                                {/* <div className="xl:w-[580px] lg:w-[470px] md:w-[350px] " data-aos="fade-right"> */}
                                <img className="rounded-3xl w-full  md:h-full object-cover md:block hidden" src={carrerAtCRI} alt="" />
                            </div>
                            <div
                                className="flex-1 text-white h-full lg:space-y-7 space-y-5 md:text-xl text-sm font-normal md:w-1/2 "
                                data-aos="fade-left"
                            >
                                {/* <div className="flex-1 text-white h-full lg:space-y-7 space-y-5 md:text-xl text-sm font-normal " data-aos="fade-left"> */}
                                <div className="xl:text-4xl lg:text-3xl md:text-2xl text-3xl font-semibold">Career at CRI</div>
                                <div className="xl:text-xl lg:text-lg md:text-base text-sm space-y-5">
                                    <p className=" font-light" data-aos="zoom-in-up">
                                        Joining CRI is an opportunity to work with people who, our consultants come from the best stories in
                                        Indonesia.
                                    </p>
                                    <p className="font-light " data-aos="zoom-in-up">
                                        When you work here, you can help the development of important institutions in Indonesia and other countries to
                                        grow sustainably. We welcome applications from great talents and experienced professionals.
                                    </p>
                                </div>
                                <div>
                                    <Link to={"/career"}>
                                        <Button title="See Our Vacancy" status="secondary" className={""} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Section>
                </div>
            )}
        </div>
    );
}
