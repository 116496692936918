import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { FaArrowLeft } from "react-icons/fa6";
import { industries } from "../../Data/data";
import Breadcrumbs from "../../components/Breadcrumbs";
import AOS from "aos";
import useFetchApiGet from "../../Hooks/useFetchApiGet";

export default function ServiceDetail() {
    const { id } = useParams();
    const service = industries.find((industry) => industry.id === Number(id));

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: "Services", path: "/services" },
        { label: service ? service.title : "Service Detail", path: `/services/${id}` },
    ];

    const { data: industryDetail, fetchApiGet: fetchApiGetIndustryDetail, setData: setIndustryDetail } = useFetchApiGet();

    const onGetIndustryDetail = async (id) => {
        try {
            const response = await fetchApiGetIndustryDetail(`/industry/detail/${id}`);
            if (response.status === 200) {
                const formatData = response.data.data;
                setIndustryDetail(formatData);
            }
        } catch (error) {
            console.error("Error fetching services:", error);
        }
    };
    console.log(industryDetail);
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
            offset: 150,
            easing: "ease",
        });
    }, []);
    useEffect(() => {
        onGetIndustryDetail(id);
    }, []);

    return (
        <div className="h-full w-full ">
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden "
                className="md:h-[19rem] h-[6rem] px-10 relative md:py-10 max-md:hidden"
                position="end"
            >
                <div className="absolute inset-0 bg-gradient-to-r from-[#0C2665] via-[#0b235b] to-[#0C2665]/50 from-5% via-30%  transition-all duration-1000 ease-in-out hover:blur-md z-20 max-md:hidden"></div>

                {/* <img className="absolute right-0 top-0 w-2/3 h-full object-cover z-10 max-md:hidden z-10" src={service.backgroundImage} alt="" /> */}
                <div className="absolute z-20 max-md:hidden">
                    <div className="text-4xl space-y-1">
                        <div className="absolute w-full top-0 bg-gradient-to-r from-blue-950/100 to-blue-300/5 z-20 opacity-20"></div>
                        <div data-aos="fade-down">{industryDetail ? industryDetail.title : "Service Not Found"}</div>
                    </div>
                </div>
            </Header>
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="md:h-[6rem] max-md:h-[6rem] xl:px-32 md:px-14 px-10 text-white hover:text-blue-400 md:hidden "
            />

            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <div className="relative flex flex-col justify-center items-center xl:px-16 lg:px-10 md:py-10 p-2 font-normal w-full bg-background">
                <div className="bg-white xl:px-16 lg:px-10 md:py-10 p-5">
                    {industryDetail ? (
                        <div>
                            {industryDetail.standard && (
                                <p
                                    style={{ padding: 0 }}
                                    className="ql-editor"
                                    data-aos="fade-left"
                                    dangerouslySetInnerHTML={{ __html: industryDetail.standard }}
                                ></p>
                            )}
                        </div>
                    ) : (
                        <p>Service Detail not found.</p>
                    )}
                </div>
                {/* <button
                    className="absolute bottom-10 left-20 flex text-sm justify-center items-center space-x-5 hover:text-blue-400 hover:cursor-pointer"
                    onClick={handleBack}
                >
                    <FaArrowLeft />
                    <h1>Back</h1>
                </button> */}
            </div>
        </div>
    );
}
