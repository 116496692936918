import React, { useState } from "react";
import clistelogo from "../assets/cliste-logo.png";
import iso from "../assets/iso.png";
import { FaGlobe, FaInstagram, FaLinkedin, FaMailBulk, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import cliste from "../assets/cliste.svg";
import IsoLogo from "../assets/IsoLogo.svg";

export default function Footer() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleOpenNewTabHeadOffice = () => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
            window.open("https://maps.app.goo.gl/YZ8E8bKaBzJv47T68", "_blank");
        }, 3000);
    };

    const handleOpenNewTabRemoteOffice = () => {
        window.open("https://maps.app.goo.gl/6wtr1ec1R6xBJvV59", "_blank");
    };
    const handleOpenNewTabInstagram = () => {
        window.open("https://www.instagram.com/clisterekayasaindonesia/", "_blank"); // Opens internal route in a new tab
    };
    const handleOpenNewTabLinkedin = () => {
        window.open("https://www.linkedin.com/company/pt-cliste-rekayasa-indonesia/mycompany/", "_blank"); // Opens internal route in a new tab
    };
    return (
        <div className="bg-[#003478] text-white text-xl px-6 xl:px-36 md:px-10 py-10 md:py-5 w-full">
            <div className="lg:flex w-full space-y-10 lg:space-y-0 py-5 xl:max-w-[1560px] mx-auto">
                <div className="space-y-6 font-light lg:w-[45rem] ">
                    <div className="flex justify-start items-center space-x-10 max-md:hidden w-1/2">
                        <div className="w-1/2">
                            <img src={cliste} alt="" />
                        </div>
                        <div className="flex-1">
                            <img className="" src={IsoLogo} alt="" />
                        </div>
                    </div>
                    <div className="flex justify-start items-center space-x-10 md:hidden ">
                        <div className="w-1/2">
                            <img src={cliste} alt="" />
                        </div>
                        <div className="flex-1">
                            <img className="" src={IsoLogo} alt="" />
                        </div>
                    </div>
                    <section className="text-sm max-w-md font-normal">
                        Indonesia-based consultant pioneer in advanced Safety & Reliability asset management assessment, precise reverse engineering,
                        innovative software, and excellent new product development.
                    </section>
                </div>

                <div className=" space-y-5 font-light text-xs flex-1">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <div className="flex justify-center space-x-2 items-start" onClick={handleOpenNewTabHeadOffice}>
                            <FaMapMarkerAlt className="text-white h-5 w-5" />
                            <div className="flex-1 space-y-2 px-2">
                                <h3 className="font-semibold">Head Office :</h3>
                                <a href="https://maps.app.goo.gl/YZ8E8bKaBzJv47T68" className="hover:text-blue-200">
                                    <p>Gandaria 8 Office Tower 8 Floor Jl. Sultan Iskandar Muda, Jakarta Selatan, 12440</p>
                                </a>
                            </div>
                        </div>
                    )}

                    <div className="flex justify-center space-x-2 items-start">
                        <FaMapMarkerAlt className="text-white h-5 w-5" />
                        <div className="flex-1 space-y-2 px-2" onClick={handleOpenNewTabHeadOffice}>
                            <h3 className="font-semibold">Remote Office :</h3>
                            <p>
                                <a href="https://maps.app.goo.gl/6wtr1ec1R6xBJvV59" className="hover:text-blue-200">
                                    Jl. Cempaka Lestari IV Blok G No.3 RT/RW 005/007 Kel Lb. Bulus, Kec. Cilandak Jakarta Selatan 12440
                                </a>
                            </p>
                        </div>
                    </div>

                    <div className="flex space-x-4 items-start hover:cursor-pointer  hover:text-blue-200">
                        {/* <FaPhoneAlt className=" h-5 w-5" /> */}
                        <IoMdMail className="h-5 w-5" />
                        <p className="flex-1">
                            <a href="mailto:office@cliste.co.id">office@cliste.co.id </a>
                        </p>
                    </div>
                    <div className="flex space-x-4 items-start hover:cursor-pointer  hover:text-blue-200">
                        <FaPhoneAlt className=" h-5 w-5" />
                        <a href="tel:+6281219810869">
                            <p className="flex-1">+62 21 29851728 / +62 812 1981 0869</p>
                        </a>
                    </div>
                    <div className="flex space-x-4 items-start hover:cursor-pointer hover:text-blue-200">
                        <FaGlobe className="h-5 w-5" />
                        <a href="/">
                            <p className="flex-1">www.cliste.co.id</p>
                        </a>
                    </div>
                    <div className="w-full flex space-x-10 items-center text-xs font-normal max-md:hidden">
                        <div className="flex space-x-4 items-center hover:text-blue-200 hover:cursor-pointer" onClick={handleOpenNewTabInstagram}>
                            <FaInstagram className="w-5 h-5" />
                            <div className="flex-1 ">
                                <a>Clisterekayasaindonesia</a>
                            </div>
                        </div>
                        <div className="flex space-x-4 items-center hover:cursor-pointer hover:text-blue-200 " onClick={handleOpenNewTabLinkedin}>
                            <FaLinkedin className="w-5 h-5 " />
                            <div className="flex-1">
                                <a>PT Cliste Rekayasa Indonesia</a>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex space-x-4 items-center hover:text-blue-200 hover:cursor-pointer md:hidden"
                        onClick={handleOpenNewTabInstagram}
                    >
                        <FaInstagram className="w-5 h-5" />
                        <div className="flex-1 ">
                            <a>Clisterekayasaindonesia</a>
                        </div>
                    </div>
                    <div
                        className="flex space-x-4 items-center hover:cursor-pointer hover:text-blue-200  md:hidden"
                        onClick={handleOpenNewTabLinkedin}
                    >
                        <FaLinkedin className="w-5 h-5 " />
                        <div className="flex-1">
                            <a>PT Cliste Rekayasa Indonesia</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full h-px bg-[#B4B4B4] my-8 md:my-4"></div>

            <p className="text-center text-gray-100 text-sm">© 2024 PT Cliste Rekayasa Indonesia</p>
        </div>
    );
}
