import React from "react";
import clisteLogo from "../assets/criBiru.svg";
import { motion } from "framer-motion";

export default function Loading({  }) {
    return (
        <div className="loading-container bgblue bg-background h-screen">
            <div className="logo-container">
                <motion.img
                    className="max-md:hidden"
                    src={clisteLogo}
                    alt="loading..."
                    initial={{ scale: 0.15 }}
                    animate={{ scale: 0.4 }}
                    transition={{ duration: 5, ease: "easeIn" }}
                />

                {/* mobile */}
                <motion.img
                    className="mdhid md:hidden"
                    src={clisteLogo}
                    alt="loading..."
                    initial={{ scale: 0.15 }}
                    animate={{ scale: 0.35 }}
                    transition={{ duration: 3, ease: "easeIn" }}
                />
            </div>
        </div>
    );
}
