import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/Header";

import pdf from "../../../assets/dummies.pdf";
import Breadcrumbs from "../../../components/Breadcrumbs";
import useFetchApiGet from "../../../Hooks/useFetchApiGet";

import "quill/dist/quill.snow.css";
import Quill from "quill";
import DownloadPDF from "../../../components/DownloadPDF";
import FormatDateToText from "../../../Constant/FormatDateToText";

export default function PaperDetail() {
    const { id } = useParams();
    const getQuillHTML = (deltaData) => {
        const quill = new Quill(document.createElement("div"));
        quill.setContents(deltaData);
        return quill.root.innerHTML;
    };

    const [paperDetail, setPaperDetail] = useState({});

    const { fetchApiGet: fetchApiGetPaperDetail, loading: loading } = useFetchApiGet();
    const onGetPaper = async (id) => {
        try {
            const response = await fetchApiGetPaperDetail(`/paper/detail/${id}`);

            const getReadTime = (summary) => {
                const wordCount = summary.join(" ").split(" ").length;
                const wordsPerMinute = 225;
                const timeInMinutes = Math.ceil(wordCount / wordsPerMinute);
                return `${timeInMinutes} min read`;
            };
            if (response.status === 200) {
                const paper = response.data.data;
                const paperDetail = {
                    ...paper,
                    dateText: FormatDateToText(paper.date),
                };
                console.log("paper detail", paperDetail);
                setPaperDetail(paperDetail);
            }
        } catch (error) {
            console.error();
        }
    };

    const navigate = useNavigate();

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [showPDF, setShowPDF] = useState(false);

    const handleBack = () => {
        navigate(-1);
    };

    const pdfView = () => {
        setShowPDF(true);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "Our Insight", path: "/ourInsight" },
        { label: paperDetail.title, path: `/ourInsight/paper/${id}` },
    ];
    useEffect(() => {
        onGetPaper(id);
    }, []);

    return (
        <div>
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="md:h-[6rem] max-md:h-[6rem] xl:px-32 md:px-14 px-10"
            ></Header>
            <Breadcrumbs breadcrumbs={breadcrumb} />

            <div className="relative flex mx-auto flex-col lg:w-2/3 items-center xl:px-32  md:py-20 p-5 font-normal bg-[#F6FBFF] ">
                {loading ? (
                    <div className="text-center text-gray-400 text-xl flex justify-center items-center animate-pulse duration-700 ease-in">
                        Loading content...
                    </div>
                ) : paperDetail ? (
                    <div className="w-full text-[#676767] space-y-5 bg-white p-8 shadow-lg drop-shadow-lg md:rounded-xl n">
                        <div className="header space-y-10  xl:flex xl:items-start h-full">
                            <div className="xl:flex-1">
                                <div className="font-normal text">
                                    <div>{paperDetail.title && <h1 className="text-2xl font-semibold">Title: {paperDetail.title}</h1>}</div>
                                    <div className="text-[#9E9E9E] max-md:text-xs">{paperDetail.dateText && <p>Date: {paperDetail.dateText}</p>}</div>
                                    <div className="text-[#9E9E9E] max-md:text-xs">
                                        <Link className="flex space-x-2" to={paperDetail.url}>
                                            <span> URL:</span>

                                            <p className="hover:text-blue-600"> Click Here</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="xl:w-1/4 xl:h-full font-semibold space-x-5 space-y-4 max-md:text-xs flex justify-end">
                                <DownloadPDF fileData={paperDetail.file} fileName={paperDetail.title + Date.now()} key={paperDetail.file} />
                            </div>
                        </div>
                        <div className="w-full">
                            <h1 className="font-semibold">Abstract</h1>
                            <p style={{ padding: 0 }} className="ql-editor" dangerouslySetInnerHTML={{ __html: paperDetail.abstracts }}></p>
                        </div>
                    </div>
                ) : (
                    <p>Paper detail not found.</p>
                )}
            </div>
        </div>
    );
}
