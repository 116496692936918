import React, { useEffect } from "react";
import Header from "../../../components/Header";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { articlePaper, vacancies } from "../../../Data/data";
import { GoPeople } from "react-icons/go";
import { IoBagRemoveOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import useFetchApiGet from "../../../Hooks/useFetchApiGet";
import FormatDateToText from "../../../Constant/FormatDateToText";
import Button from "../../../components/Button";
import Breadcrumbs from "../../../components/Breadcrumbs";

export default function OurVacanciesDetail() {
    const { id } = useParams();

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const { data: listVacancy, setData: setListVacancy, fetchApiGet: fetchApiGetVacancy, loading: loadingArticle } = useFetchApiGet();
    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "Career", path: "/career" },
        { label: "Vacancies", path: "/career/vacancies" },
        { label: `${listVacancy.title}`, path: `{/career/vacancies/${id}}` },
    ];

    const onGetVacancyDetail = async () => {
        try {
            const response = await fetchApiGetVacancy(`/vacancy/detail/${id}`);
            if (response.status === 200) {
                const data = response?.data.data
                    .map((item) => ({
                        ...item,
                        dateText: FormatDateToText(item.date),
                        parsedDate: new Date(item.date),
                    }))
                    // .filter((item) => item.parsedDate.getTime() <= currentDate.getTime())
                    .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
                setListVacancy(data);
                console.log("data", data);
            }
            console.log("res", response);
        } catch (error) {
            console.error("Error fetching service data:", error);
        }
    };

    const handleApply = () => {
        navigate(`/vacancies/apply/${listVacancy.id}`);
    };
    const vacancy = vacancies.find((vac) => vac.id === parseInt(id));

    useEffect(() => {
        onGetVacancyDetail();
    }, []);

    return (
        <div>
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="md:h-[25rem] h-screen max-md:hidden "
                position="end"
            >
                <div className="text-4xl">
                    <p className="text-lg font-normal">Work with us</p>
                    <div className="text-4xl">{listVacancy.title}</div>
                </div>
            </Header>
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="max-md:h-[5rem]  px-10 md:hidden"
                position="center"
            >
                {/* <button className="text-base flex justify-center items-center space-x-4 " onClick={handleBack}>
                    <FaArrowLeft />
                    <h1>Back</h1>
                </button> */}
            </Header>
            <Breadcrumbs breadcrumbs={breadcrumb} />

            <div className="relative bg-white xl:px-32 md:px-10 px-5 xl:py-20 font-normal space-y-10 pb-20 my-10">
                <div className="xl:text-4xl md:text-3xl text-3xl space-y-1 font-semibold md:hidden ">
                    <p className="text-base font-normal">Work with us</p>
                    <div>{listVacancy.title}</div>
                </div>
                <div className="flex xl:space-x-10 space-x-4 text-sm flex-1">
                    <div className="flex space-x-1   justify-center items-center">
                        <GoPeople />
                        <h1>{listVacancy.working_mode}</h1>
                    </div>
                    <div className="flex space-x-1   justify-center items-center">
                        <IoBagRemoveOutline />
                        <h1>{listVacancy.level}</h1>
                    </div>
                    <div className="flex space-x-1   justify-center items-center">
                        <CiLocationOn />
                        <h1>{listVacancy.location}</h1>
                    </div>
                </div>
                {listVacancy.description && (
                    <p
                        style={{ padding: 0 }}
                        className="ql-editor"
                        data-aos="fade-left"
                        dangerouslySetInnerHTML={{ __html: listVacancy.description }}
                    ></p>
                )}
                <div className="absolute md:bottom-5 md:left-28 max-md:left-4 text-sm text-[#fefefe] px-5 ">
                    <Button title="Apply" status="primary" type="button" onClick={handleApply} />
                    {/* <button className="bg-[#003478] px-5 py-2 hover:bg-[#012a5f]" onClick={handleApply}>
                        Apply
                    </button> */}
                </div>
            </div>
        </div>
    );
}
