import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft, FaSearch } from "react-icons/fa";

import { vacancies } from "../../../Data/data";
import { GoPeople } from "react-icons/go";
import { IoBagRemoveOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import Breadcrumbs from "../../../components/Breadcrumbs";
import useFetchApiGet from "../../../Hooks/useFetchApiGet";
import FormatDateToText from "../../../Constant/FormatDateToText";
import { Toaster } from "react-hot-toast";

export default function OurVacancies() {
    const navigate = useNavigate();
    const [searchActive, setSearchActive] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const { data: listVacancy, setData: setListVacancy, fetchApiGet: fetchApiGetVacancy, loading: loadingArticle } = useFetchApiGet();

    
    const onGetVacancy = async () => {
        // const currentDate = new Date();

        try {
            const response = await fetchApiGetVacancy(`/vacancy/list/1/100/all`);
            if (response.status === 200) {
                const data = response?.data.data
                    .map((item) => ({
                        ...item,
                        dateText: FormatDateToText(item.date),
                        parsedDate: new Date(item.date),
                    }))
                    // .filter((item) => item.parsedDate.getTime() <= currentDate.getTime())
                    .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
                setListVacancy(data);
                console.log("data", data);
            }
            console.log("res", response);
        } catch (error) {
            console.error("Error fetching service data:", error);
        }
    };

    console.log("L Vacan", listVacancy);
    const handleBack = () => {
        navigate(-1);
    };

    const toggleSearch = () => {
        setSearchActive(!searchActive); // Toggle state search
    };

    const [isChecked, setIsChecked] = useState(false);

    const { id } = useParams();
    const vacancy = vacancies.find((vac) => vac.id === parseInt(id));

    const handleReadMore = (id) => {
        navigate(`/career/vacancies/${id}`);
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const handleCheckboxChange = (tag) => {
        setSelectedTags((prevSelectedTags) => {
            if (prevSelectedTags.includes(tag)) {
                return prevSelectedTags.filter((t) => t !== tag);
            } else {
                return [...prevSelectedTags, tag];
            }
        });
    };
    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "Career", path: "/career" },
        { label: "Vacancies", path: "/career/vacancies" },
    ];
    const level = listVacancy
        .map((item) => item.level)
        .flat()
        .filter((value, index, self) => self.indexOf(value) === index);

    const filteredVacancy = listVacancy.filter((data) => {
        const matchesTags = selectedTags.length === 0 || data.title.some((tag) => selectedTags.includes(tag));

        const matchesSearchQuery = data.title.toLowerCase().includes(searchQuery);

        return matchesTags && matchesSearchQuery;
    });
    console.log("tags", level);
    console.log("vac", listVacancy);

    useEffect(() => {
        onGetVacancy();
    }, []);

    return (
        <div className="bg-[#F6FBFF]">
            <Toaster
                position="top-right"
                toastOptions={{
                    duration: 1000,
                    style: {
                        background: "#333",
                        color: "#fff",
                    },
                }}
            />
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="md:h-[25rem] h-screen max-md:hidden "
                position="end"
            >
                {/* <button className="text-base flex justify-center items-center space-x-4 " onClick={handleBack}>
                    <FaArrowLeft />
                    <h1>Back</h1>
                </button> */}
                <div className="text-4xl space-y-1">
                    <p className="text-sm font-normal">Work with us</p>
                    <div>Let’s growth your potential with CRI</div>
                </div>
            </Header>
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="md:h-[25rem] max-md:h-screen xl:px-32 md:px-14 px-10 md:hidden"
                position="center"
            >
                {/* <button className="text-base flex justify-center items-center space-x-4 " onClick={handleBack}>
                    <FaArrowLeft />
                    <h1>Back</h1>
                </button> */}
                <div className="text-4xl space-y-1 ">
                    <p className="text-sm font-normal">Work with us</p>
                    <div>Let’s growth your potential with CRI</div>
                </div>
            </Header>
            <Breadcrumbs breadcrumbs={breadcrumb} />
            <div className="xl:px-32 lg:px-20 md:px-16 py-10 text-[#616161]">
                <div className=" w-full space-y-10">
                    <div className="flex flex-col justify-center items-center">
                        <h1 className="text-4xl font-semibold mb-6 max-md:px-5">Our Available Vacancies</h1>
                        <div className="w-full">
                            <div className="flex justify-center items-center space space-x-3">
                                <FaSearch className="z-30" />
                                <input
                                    type="search"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="p-2 border border-gray-500/20 rounded-md text-sm font-light focus:border-gray-500/40"
                                    placeholder="Search Vacancies..."
                                />
                            </div>
                        </div>
                        {/* <button
                                onClick={toggleSearch}
                                className="flex items-center justify-end w-full  bg-[#FFFFFF1A]/10 text-black hover:border-white px-4 py-2 hover:border-2 rounded-lg border border-teal-100 transition-all duration-300 ease-in-out z-0"
                            >
                                <FaSearch />
                            </button> */}
                    </div>
                    <div className="space-y-10 w-full">
                        <div className="flex max-xl:flex-col justify-center items-start w-full h-full">
                            {/* <div className="md:w-1/4 max-md:my-10 flex flex-col  max-md:justify-start items-start h-full w-full xl:space-y-10 max-sm:text-xs">
                                <div className="hidden lg:flex h-full lg:flex-col lg:justify-start lg:space-y-2 space-x-4 lg:space-x-0 lg:items-start justify-center items-center md:overflow-x-auto overflow-x-scroll w-full">
                                    {level.map((tag, index) => (
                                        <div key={index} className="flex items-center space-x-3">
                                            <input
                                                type="checkbox"
                                                id={tag}
                                                className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                checked={selectedTags.includes(tag)}
                                                onChange={() => handleCheckboxChange(tag)}
                                            />
                                            <label htmlFor={tag} className="text-gray-700 font-light">
                                                {tag}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div> */}
                            <div className="flex-1 h-full w-full">
                                <div className="h-full grid gap-6">
                                    {filteredVacancy.length === 0 ? (
                                        <div className="text-center text-gray-400 text-xl font-medium h-[27rem] flex justify-center items-center animate-pulse duration-700 ease-in">
                                            No content available for the selected tags or search query.
                                        </div>
                                    ) : (
                                        <div className="space-y-5">
                                            {filteredVacancy.map((vacancy) => (
                                                <div
                                                    key={vacancy.id}
                                                    className="relative p-6 bg-white/30 rounded-lg shadow-md md:space-y-3 space-y-5 hover:cursor-pointer hover:bg-white/100 transition duration-300 ease-in-out md:px-5"
                                                    onClick={() => handleReadMore(vacancy.id)}
                                                >
                                                    <h2 className="text-xl font-semibold">{vacancy.title}</h2>
                                                    <div className="flex md:space-x-10 max-md:space-x-3 text-sm">
                                                        <div className="flex space-x-2 justify-center items-center">
                                                            <GoPeople />
                                                            <h1>{vacancy.working_mode}</h1>
                                                        </div>
                                                        <div className="flex space-x-2 justify-center items-center">
                                                            <IoBagRemoveOutline />
                                                            <h1>{vacancy.level}</h1>
                                                        </div>
                                                        <div className="flex space-x-2 justify-center items-center">
                                                            <CiLocationOn />
                                                            <h1>{vacancy.location}</h1>
                                                        </div>
                                                    </div>
                                                    <div className="md:absolute right-10 bottom-10 ">
                                                        <button onClick={() => handleReadMore(vacancy.id)}>Read More</button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
