import React, { useState, useEffect } from "react";
import AOS from "aos";
import Header from "../../components/Header";
import clisteLogo from "../../assets/3dLogoCRIRev.svg";
import layerCard from "../../assets/layerCard.png";
import { useNavigate } from "react-router-dom";

import { FaArrowRightLong } from "react-icons/fa6";

import { industries } from "../../Data/data";
// import { industries, services } from "../../Data/data";
import Breadcrumbs from "../../components/Breadcrumbs";
import useFetchApiGet from "../../Hooks/useFetchApiGet";
// import FormatDateToText from "../../Constant/FormatDateToText";
import Typography from "../../components/Typography";
import Button from "../../components/Button";
import { motion } from "framer-motion";

export default function ServicesCointainer() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    // const itemsPerPage = 2;
    // const totalItems = industries.length;
    // const totalPages = Math.ceil(totalItems / itemsPerPage);

    const { data: service, fetchApiGet: fetchApiGetService, setData: setService } = useFetchApiGet();
    const { data: industry, fetchApiGet: fetchApiGetIndustry, setData: setIndustry } = useFetchApiGet();

    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "Services", path: "/services" },
    ];
    const dataText = `CRI specialize in providing comprehensive, reliability-based asset life cycle management. Our expertise spans every phase of the asset life cycle, from conception and design to construction, commissioning, operation, and decommissioning.
                    /nAs a world-class consultancy support company, CRI offers a wide range of services designed to meet the highest standards of Safety, Reliability, Availability, Maintainability, and Asset Integrity. Our offerings include: Consultancy Services (Expert guidance to optimize your operations and ensure reliability), Training (Comprehensive training programs to enhance your team’s skills and knowledge), Software Renting and Development (Customizable software solutions tailored to your specific needs), Reverse Engineering (Innovative solutions to replicate and improve existing systems), Product Development (Cutting-edge product development to meet industry demands), and Manpower Support (Skilled professionals to support your operational needs).
                    /nOur commitment to Maintenance & Reliability Excellence (MREX) ensures that we deliver unparalleled service and support, helping you achieve operational excellence and efficiency. At CRI, our primary focus is on delivering the best possible outcomes for our clients. Trust us to bring the highest level of expertise and dedication to your projects.`;
    const paragraphText = dataText.split("/n");

    const onGetServices = async (id) => {
        setLoading(true); // Mulai loading
        try {
            setLoading(true);
            const response = await fetchApiGetService(`/service/list/1/10/all`);
            if (response.status === 200) {
                const formatData = response.data.data;
                setService(formatData);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching services:", error);
        }
    };
    const onGetIndustry = async (id) => {
        setLoading(true); // Mulai loading
        try {
            setLoading(true);
            const response = await fetchApiGetIndustry(`/industry/list/1/10/all`);
            if (response.status === 200) {
                const formatData = response.data.data;
                setService(formatData);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching services:", error);
        }
    };
    console.log("industry daat", industry);

    const handleDetailCardClick = (data) => {
        navigate(`/services/${data}`, { state: { data } });
    };

    const handleCardClick = (service) => {
        setSelectedService(service);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedService(null);
    };

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isModalOpen]);
    useEffect(() => {
        AOS.init({
            duration: 800,
            once: true,
            offset: 100,
            easing: "ease-in-out",
        });
    }, []);

    useEffect(() => {
        onGetServices();
        onGetIndustry();
    }, []);

    console.log("detail", selectedService);
    return (
        <div className="h-full w-full overflow-hidden">
            {isModalOpen && selectedService && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center py-15 ">
                    <div className="bg-white rounded-lg xl:p-10 md:w-[522px] xl:h-[602px] lg:h-[562px] md:h-[530px]  w-full h-screen px-5 relative overflow-y-auto overflow-hidden">
                        <div className="space-y-5 min-h-[90%]">
                            <h2 className="lg:text-3xl text-center font-bold mb-4" data-aos="fade-zoom-up">
                                {selectedService.title}
                            </h2>
                            <div>
                                <h3 className="text-xl font-medium mb-2">Services Group</h3>
                                <p className="text-sm font-normal">{selectedService.description}</p>
                            </div>
                            <div>
                                {selectedService.module && (
                                    <p
                                        style={{ padding: 0, fontSize: "14px" }}
                                        className="ql-editor"
                                        dangerouslySetInnerHTML={{ __html: selectedService.module }}
                                    ></p>
                                )}
                            </div>
                        </div>
                        <div className="flex justify-end items-center">
                            <Button title="Close" onClick={closeModal} status="primary" />
                        </div>
                    </div>
                </div>
            )}

            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="absolute top-0 h-screen md:hidden"
                position="center"
            >
                <div className="md:text-4xl text-3xl space-y-1">
                    {/* <div className="absolute w-full top-0 bg-gradient-to-r from-blue-950/100 to-blue-300/5 z-10"></div> */}

                    <div>
                        <div className="text-[#fefefe] md:text-xl text-lg font-normal">Our Services</div>
                        <div>Find best solutions for your business</div>
                        <div className="text-[#67B7FD] ">with us.</div>
                    </div>
                    <img className="absolute right-16 -bottom-10 scale-125 md:visible hidden" src={clisteLogo} alt="" />
                </div>
            </Header>
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="absolute top-0 xl:h-[504px] lg:h-[400px] md:h-[300px] h-screen max-md:hidden"
                imageContent={clisteLogo}
                position="end"
            >
                <div className="md:text-4xl text-3xl space-y-1">
                    <div className="absolute w-full top-0 bg-gradient-to-r from-blue-950/100 to-blue-300/5 z-10"></div>
                    <div className="flex justify-center items-center">
                        <div className="flex w-full relative justify-start max-w-[1920px]">
                            <Typography variant="h1" color="" align="" className="leading-tight">
                                <div className="text-[#fefefe] xl:text-xl lg:text-lg md:text-base text-lg font-normal" data-aos="fade-down">
                                    Our Services
                                </div>
                                <div className="xl:text-4xl lg:text-3xl md:text-2xl text-4xl" data-aos="fade-down">
                                    Find best solutions for your business
                                </div>
                                <div className="text-[#67B7FD] xl:text-4xl lg:text-3xl md:text-2xl text-4xl" data-aos="fade-down">
                                    with us.
                                </div>
                            </Typography>
                            <img
                                className="absolute xl:-right-6 xl:-bottom-28 lg:-bottom-20 md:-bottom-14 -right-6 -bottom-28 xl:w-[450px] lg:w-[350px] md:w-[255px] md:visible drop-shadow-lg"
                                src={clisteLogo}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </Header>

            <main className="md:px-10  md:text-lg text-sm space-y-10 text-[#616161] bg-background">
                <Breadcrumbs breadcrumbs={breadcrumb} />
                <div className="lg:space-y-20 md:space-y-14 px-5">
                    <div className="xl:w-[1560px] max-w-screen-xl mx-auto">
                        <div className="xl:px-14 lg:px-12 lg:py-5 font-normal text-base xl:text-xl lg:text-lg md:text-base  max-md:text-sm ">
                            {paragraphText.map((paragraph, index) => (
                                <p key={index} className="mb-4" data-aos="zoom-in">
                                    {paragraph}
                                </p>
                            ))}
                        </div>
                    </div>
                    <div className="py-4 space-y-7 text-netural_90 xl:w-[1360px] mx-auto">
                        <h1 className="font-semibold text-3xl md:text-4xl text-center text-text_90" data-aos="fade-down">
                            Our Services
                        </h1>
                        <p className="text-sm md:text-base lg:text-lg xl:text-xl text-center font-normal text-text_70">
                            Our commitment is to deliver the highest quality in our every service and product – keeping your company’s performance
                            in-line with international standards.
                        </p>

                        <div className="flex w-full h-full justify-center">
                            {loading ? (
                                <div className="grid grid-cols-3 gap-10 h-full w-full hover:cursor-pointer snap-x snap-mandatory">
                                    {Array.from({ length: 3 }).map((index, data) => (
                                        <motion.div
                                            key={index}
                                            className="bg-slate-300 rounded-2xl overflow-hidden relative text-white flex-shrink-0 snap-center h-[14rem] md:h-[18.875rem]"
                                            onClick={() => handleCardClick(data)}
                                            animate={{
                                                opacity: [0.5, 1, 0.5],
                                                scale: [1, 1.02, 1],
                                            }}
                                            transition={{
                                                duration: 1.5,
                                                repeat: Number.POSITIVE_INFINITY,
                                                ease: "easeInOut",
                                            }}
                                        >
                                            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50" />
                                            <div className="absolute z-30 bottom-0 px-5 py-3 space-y-2 md:px-7 md:py-5 w-full">
                                                <motion.div
                                                    className="h-6 md:h-8 bg-gray-400 rounded-md w-3/4"
                                                    animate={{ opacity: [0.5, 1, 0.5] }}
                                                    transition={{
                                                        duration: 1.5,
                                                        repeat: Number.POSITIVE_INFINITY,
                                                        ease: "easeInOut",
                                                    }}
                                                />
                                                <motion.div
                                                    className="h-4 md:h-5 bg-gray-400 rounded-md w-full"
                                                    animate={{ opacity: [0.5, 1, 0.5] }}
                                                    transition={{
                                                        duration: 1.5,
                                                        repeat: Number.POSITIVE_INFINITY,
                                                        ease: "easeInOut",
                                                        delay: 0.2,
                                                    }}
                                                />
                                                <div className="flex justify-start items-center space-x-2">
                                                    <motion.div
                                                        className="h-4 bg-gray-400 rounded-md w-24"
                                                        animate={{ opacity: [0.5, 1, 0.5] }}
                                                        transition={{
                                                            duration: 1.5,
                                                            repeat: Number.POSITIVE_INFINITY,
                                                            ease: "easeInOut",
                                                            delay: 0.4,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </motion.div>
                                    ))}
                                </div>
                            ) : (
                                <div className="card flex space-x-5 md:space-x-10 overflow-x-auto snap-x snap-mandatory px-4 md:px-0 w-full hover:cursor-pointer justify-start">
                                    {service.map((data) => (
                                        <div
                                            key={data.id}
                                            className="bg-slate-300 rounded-2xl overflow-hidden relative text-white flex-shrink-0 snap-center h-[14rem] md:h-[18.875rem]"
                                            data-aos="zoom-in"
                                            data-aos-duration="1000"
                                            onClick={() => handleCardClick(data)}
                                        >
                                            <img
                                                className="absolute z-20 w-full h-full object-cover pointer-events-none rounded-2xl"
                                                src={layerCard}
                                                alt=""
                                            />
                                            <img
                                                className="w-full h-full object-cover transform transition duration-500 hover:scale-105"
                                                src={`data:image/png;base64,${data.image}`}
                                                alt={data.title}
                                            />
                                            <div className="absolute z-30 bottom-0 px-5 py-3 space-y-2 md:px-7 md:py-5">
                                                <h1 className="font-semibold text-lg md:text-2xl">{data.title}</h1>
                                                <p className="text-xs md:font-light">{data.description}</p>
                                                <div className="flex justify-start items-center space-x-2">
                                                    <span className="text-xs">More Details</span>
                                                    <FaArrowRightLong className="text-xs" />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="py-4 space-y-7 text-[#404040] xl:w-[1360px] mx-auto ">
                        <h1 className="font-semibold  md:text-4xl text-3xl text-center text-text_90 " data-aos="fade-down">
                            Industries
                        </h1>
                        <div className="flex w-full h-full justify-center">
                            {loading ? (
                                <div className="grid grid-cols-3 gap-10 h-full w-full hover:cursor-pointer snap-x snap-mandatory">
                                    {Array.from({ length: 3 }).map((index, data) => (
                                        <motion.div
                                            key={index}
                                            className="bg-slate-300 rounded-2xl overflow-hidden relative text-white flex-shrink-0 snap-center h-[14rem] md:h-[18.875rem]"
                                            onClick={() => handleCardClick(data)}
                                            animate={{
                                                opacity: [0.5, 1, 0.5],
                                                scale: [1, 1.02, 1],
                                            }}
                                            transition={{
                                                duration: 1.5,
                                                repeat: Number.POSITIVE_INFINITY,
                                                ease: "easeInOut",
                                            }}
                                        >
                                            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50" />
                                            <div className="absolute z-30 bottom-0 px-5 py-3 space-y-2 md:px-7 md:py-5 w-full">
                                                <motion.div
                                                    className="h-6 md:h-8 bg-gray-400 rounded-md w-3/4"
                                                    animate={{ opacity: [0.5, 1, 0.5] }}
                                                    transition={{
                                                        duration: 1.5,
                                                        repeat: Number.POSITIVE_INFINITY,
                                                        ease: "easeInOut",
                                                    }}
                                                />
                                                <motion.div
                                                    className="h-4 md:h-5 bg-gray-400 rounded-md w-full"
                                                    animate={{ opacity: [0.5, 1, 0.5] }}
                                                    transition={{
                                                        duration: 1.5,
                                                        repeat: Number.POSITIVE_INFINITY,
                                                        ease: "easeInOut",
                                                        delay: 0.2,
                                                    }}
                                                />
                                                <div className="flex justify-start items-center space-x-2">
                                                    <motion.div
                                                        className="h-4 bg-gray-400 rounded-md w-24"
                                                        animate={{ opacity: [0.5, 1, 0.5] }}
                                                        transition={{
                                                            duration: 1.5,
                                                            repeat: Number.POSITIVE_INFINITY,
                                                            ease: "easeInOut",
                                                            delay: 0.4,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </motion.div>
                                    ))}
                                </div>
                            ) : (
                                <div className="w-full space-y-10">
                                    <div
                                        className="card flex  justify-start space-x-5 md:space-x-10 overflow-x-scroll px-4 md:px-0 w-full"
                                        data-aos="zoom-in"
                                        data-aos-duration="1000"
                                    >
                                        {industries.map((data) => (
                                            <div
                                                key={data.id}
                                                className="w-[18rem] md:w-[24.125rem] h-[14rem] md:h-[18.875rem] bg-slate-300 rounded-2xl overflow-hidden relative text-white flex-shrink-0 hover:cursor-pointer"
                                                onClick={() => handleDetailCardClick(data)}
                                            >
                                                <img
                                                    className="absolute z-20 w-full h-full object-cover pointer-events-none rounded-2xl"
                                                    src={layerCard}
                                                    alt=""
                                                />
                                                <img
                                                    className="w-full h-full object-cover transform transition duration-500 hover:scale-105"
                                                    src={data.image}
                                                    alt={data.title}
                                                />
                                                <div className="absolute z-30 bottom-0 px-5 py-3 space-y-2 md:px-7 md:py-5">
                                                    <h1 className="font-semibold text-lg md:text-2xl">{data.title}</h1>
                                                    <p className="text-xs md:font-light">{data.description}</p>
                                                    <div className="flex justify-start items-center space-x-2">
                                                        <span className="text-xs">More Details</span>
                                                        <FaArrowRightLong className="text-xs" />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
