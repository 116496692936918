/* eslint-disable react-hooks/exhaustive-deps */
import axios from "../app/api/Index";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const useFetchApiDownload = (url) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("");
    const [blob, setBlob] = useState(null);

    const [data, setData] = useState([]);

    useEffect(() => {
        if (loading === false && status) {
            if (status === 200 || status === 201) {
                toast.success(message);
            } else if (status === 401) {
                window.location.reload();
            } else {
                toast.error(message);
            }
        }
    }, [loading]);

    const fetchApiDownload = async (url, option, filename, type) => {
        setLoading(true);
        await axios
            .get(url, option)
            .then((response) => {
                const contentType = response.headers["content-type"];

                const url = window.URL.createObjectURL(new Blob([response.data], { type: type || contentType || "application/vnd.ms-excel" }));

                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);

                document.body.appendChild(link);
                link.click();

                setLoading(false);
                setMessage("Download Success");
                setStatus(response.status);
            })
            .catch((error) => {
                setLoading(false);
                setStatus(error.response.data.status);
                setMessage("Download Error");
                // setMessage(error.response.data.message === undefined ? error.response.data.title : error.response.data.message);
            });
    };

    const fetchBlobData = async (url, option, filename) => {
        setLoading(true);
        await axios
            .get(url, option)
            .then((response) => {
                const dataBlob = response.data;
                setBlob(dataBlob);
                setData(dataBlob);
                setLoading(false);
                setMessage("Download Success");
                setStatus(response);
            })
            .catch((error) => {
                setStatus(error.response.data.status);
                setMessage("Download Error");
                setLoading(false);
            });
    };

    return { data, loading, fetchApiDownload, blob, fetchBlobData, status };
};

export default useFetchApiDownload;
