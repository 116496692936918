import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import useFetchApiGet from "../../../Hooks/useFetchApiGet";
import useFetchApiPost from "../../../Hooks/useFetchApiPost";
import toast, { Toaster } from "react-hot-toast";
import Button from "../../../components/Button";
import Loading from "../../../components/Loading";
import DotLoading from "../../../components/DotLoading";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ReCAPTCHA from "react-google-recaptcha";

function ApplyForm() {
    const { id } = useParams();
    const navigate = useNavigate();

    const { data: vacancy, setData: setVacancy, loading: loadingVacancy, fetchApiGet: fetchApiGetVacancy } = useFetchApiGet();
    const { loading: loadingPost, fetchApiPost: fetchApiJobPost } = useFetchApiPost();

    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "Career", path: "/career" },
        { label: "Vacancies", path: "/career/vacancies" },
        { label: `Apply ${vacancy.title}`, path: "/career/vacancies" },
    ];

    const [formData, setFormData] = useState({
        fullname: "",
        vacancy_id: "",
        email: "",
        phone_number: "",
        domicile: "",
        url: "",
        file: null,
        salary: "",
        message: "",
    });

    // State untuk nilai yang ditampilkan di input (dengan format IDR)
    const [displaySalary, setDisplaySalary] = useState("");

    // State untuk menyimpan error per field
    const [captcha, setCaptcha] = useState();

    console.log("captcha ", captcha);

    const [errors, setErrors] = useState({
        fullname: "",
        email: "",
        phone_number: "",
        domicile: "",
        url: "",
        salary: "",
        message: "",
        file: "",
    });

    // Fungsi untuk memformat angka ke format IDR (misal: 10000000 -> 10.000.000)
    const formatCurrencyIDR = (value) => {
        if (!value || isNaN(value)) return "";
        return parseInt(value).toLocaleString("id-ID");
    };

    // Fungsi untuk parsing nilai IDR ke angka murni (misal: 10.000.000 -> 10000000)
    const parseCurrencyIDR = (value) => {
        return value.replace(/\./g, "");
    };

    // Validasi real-time untuk setiap field
    const validateField = (name, value) => {
        let error = "";
        switch (name) {
            case "fullname":
                if (!value) error = "Full Name is required";
                else if (!/^[a-zA-Z\s]{2,}$/.test(value)) error = "Full Name must contain only letters and spaces, minimum 2 characters";
                break;
            case "email":
                if (!value) error = "Email is required";
                else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) error = "Please enter a valid email address (e.g., john.doe@example.com)";
                break;
            case "phone_number":
                if (!value) error = "Phone Number is required";
                else if (!/^\+?[0-9]{10,}$/.test(value)) error = "Phone Number must be valid (e.g., +6281234567890), minimum 10 digits";
                break;
            case "domicile":
                if (!value) error = "Domicile is required";
                else if (!/^[a-zA-Z\s,]{2,}$/.test(value)) error = "Domicile must contain only letters, spaces, and commas, minimum 2 characters";
                break;
            case "url":
                if (!value) error = "LinkedIn/Portfolio URL is required";
                break;
            case "salary":
                const parsedSalary = parseCurrencyIDR(value);
                if (!parsedSalary) error = "Salary Expectation is required";
                else if (isNaN(parsedSalary) || parseInt(parsedSalary) < 1000000) error = "Salary must be a valid number and minimum 1.000.000 IDR";
                break;
            case "message":
                if (!value) error = "Message is required";
                else if (value.length < 10) error = "Message must be at least 10 characters long";
                break;
            case "file":
                if (!value) error = "CV is required";
                else if (
                    !["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(
                        value.type
                    )
                )
                    error = "CV must be a PDF, DOC, or DOCX file";
                else if (value.size > 5 * 1024 * 1024) error = "CV size must not exceed 5 MB";
                break;
            default:
                break;
        }
        return error;
    };

    const onGetVacancyDetail = async () => {
        try {
            const response = await fetchApiGetVacancy(`/vacancy/detail/${id}`);
            const data = response.data.data;
            if (data.status === 200) {
                console.log("Vacancy Details:", data.data);
                setVacancy(data);
            }
        } catch (error) {
            console.error("Error fetching vacancy details:", error.message);
        }
    };

    const onPostJob = async () => {
        let errors = {};

        // Validasi semua field sebelum submit
        Object.keys(formData).forEach((key) => {
            if (key !== "vacancy_id" && key !== "file") {
                const error = validateField(key, formData[key]);
                if (error) errors[key] = error;
            } else if (key === "file" && formData[key]) {
                const error = validateField(key, { type: formData[key].type, size: formData[key].size });
                if (error) errors[key] = error;
            }
        });

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            // toast.error(Object.values(errors).join(", \n"));
            return;
        }

        try {
            const submitData = new FormData();
            submitData.append("fullname", formData.fullname);
            submitData.append("vacancy_id", formData.vacancy_id);
            submitData.append("salary", formData.salary); // Nilai murni tanpa format
            submitData.append("message", formData.message);
            submitData.append("email", formData.email);
            submitData.append("phone_number", formData.phone_number);
            submitData.append("domicile", formData.domicile);
            submitData.append("url", formData.url);

            if (formData.file) {
                submitData.append("file", formData.file);
            }

            const response = await fetchApiJobPost("/job/add", submitData);
            if (response.status === 200 || response.status === 201) {
                toast.success("Application submitted successfully!");

                setTimeout(() => {
                    navigate("/career/vacancies");
                }, 3000);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        let newValue = value;

        if (type === "file") {
            const size = 5;
            const maxSize = size * 1024 * 1024;
            if (files[0]?.size > maxSize) {
                toast.error(`Hold up! That file is a bit too big. Try uploading a smaller one (Max: ${size} MB)`);
                e.target.value = "";
                setFormData((prev) => ({ ...prev, [name]: null }));
                setErrors((prev) => ({ ...prev, [name]: "CV size must not exceed 5 MB" }));
                return;
            } else if (files[0]) {
                const fileError = validateField(name, { type: files[0].type, size: files[0].size });
                setErrors((prev) => ({ ...prev, [name]: fileError || "" }));
                newValue = files[0];
            }
        } else if (type === "checkbox") {
            newValue = checked;
        } else if (name === "salary") {
            const parsedValue = parseCurrencyIDR(value);
            setDisplaySalary(formatCurrencyIDR(parsedValue));
            newValue = parsedValue;
        }

        setFormData((prev) => ({ ...prev, [name]: newValue }));
        const error = validateField(name, newValue);
        setErrors((prev) => ({ ...prev, [name]: error }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onPostJob();
    };

    useEffect(() => {
        onGetVacancyDetail();
    }, []);

    useEffect(() => {
        if (vacancy?.id) {
            setFormData((prev) => ({ ...prev, vacancy_id: vacancy.id }));
        }
    }, [vacancy]);

    return (
        <div className="">
            <Toaster
                position="top-right"
                toastOptions={{
                    duration: 3000,
                    style: {
                        background: "#333",
                        color: "#fff",
                    },
                }}
            />
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="md:h-[25rem] h-screen max-md:hidden "
                position="end"
            >
                <div className="text-4xl ">
                    <div>
                        <p className="text-lg font-normal">Join our team as a </p>
                        <div className="">{vacancy.title}</div>
                    </div>
                </div>
            </Header>
            <Header bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden" className="h-[7rem] md:hidden" position="center"></Header>
            <Breadcrumbs breadcrumbs={breadcrumb} />

            <form className="py-16 px-6 mx-auto max-w-4xl space-y-28" onSubmit={handleSubmit}>
                <div className="text-4xl text-center text-gray-700 font-semibold">Apply for {vacancy?.title}</div>

                <div className="grid md:grid-cols-2 gap-10">
                    <div>
                        <h1 className="text-lg font-medium">Full Name</h1>
                        <input
                            className={`p-3 w-full font-light rounded-md focus:ring-blue-500 ${errors.fullname ? "border-red-500" : ""}`}
                            type="text"
                            name="fullname"
                            placeholder="Hey, tell us your full name! (e.g., John Doe)"
                            value={formData.fullname}
                            onChange={handleChange}
                            required
                        />
                        {errors.fullname && <p className="text-red-500 text-sm mt-1">{errors.fullname}</p>}
                    </div>
                    <div>
                        <h1 className="text-lg font-medium">Email</h1>
                        <input
                            className={`p-3 w-full font-light rounded-md focus:ring-blue-500 ${errors.email ? "border-red-500" : ""}`}
                            type="email"
                            name="email"
                            placeholder="Drop your email here! (e.g., john.doe@example.com)"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                    </div>
                    <div>
                        <h1 className="text-lg font-medium">Phone Number</h1>
                        <input
                            className={`p-3 w-full font-light rounded-md focus:ring-blue-500 ${errors.phone_number ? "border-red-500" : ""}`}
                            type="tel"
                            name="phone_number"
                            placeholder="Share your number with us! (e.g., +6281234567890)"
                            value={formData.phone_number}
                            onChange={handleChange}
                            required
                        />
                        {errors.phone_number && <p className="text-red-500 text-sm mt-1">{errors.phone_number}</p>}
                    </div>
                    <div>
                        <h1 className="text-lg font-medium">Domicile</h1>
                        <input
                            className={`p-3 w-full font-light rounded-md focus:ring-blue-500 ${errors.domicile ? "border-red-500" : ""}`}
                            type="text"
                            name="domicile"
                            placeholder="Where do you hang out these days? (e.g., Jakarta)"
                            value={formData.domicile}
                            onChange={handleChange}
                            required
                        />
                        {errors.domicile && <p className="text-red-500 text-sm mt-1">{errors.domicile}</p>}
                    </div>
                    <div>
                        <h1 className="text-lg font-medium">LinkedIn / Portfolio</h1>
                        <input
                            className={`p-3 w-full font-light rounded-md focus:ring-blue-500 ${errors.url ? "border-red-500" : ""}`}
                            type="text"
                            name="url"
                            placeholder="Show off your profile! (e.g., linkedin.com/in/johndoe)"
                            value={formData.url}
                            onChange={handleChange}
                            required
                        />
                        {errors.url && <p className="text-red-500 text-sm mt-1">{errors.url}</p>}
                    </div>
                    <div>
                        <h1 className="text-lg font-medium">Salary Expectation (in IDR)</h1>
                        <input
                            className={`p-3 w-full font-light rounded-md focus:ring-blue-500 ${errors.salary ? "border-red-500" : ""}`}
                            type="text"
                            name="salary"
                            placeholder="What’s your dream salary? (e.g., 10.000.000)"
                            value={displaySalary}
                            onChange={handleChange}
                            required
                        />
                        {errors.salary && <p className="text-red-500 text-sm mt-1">{errors.salary}</p>}
                    </div>
                    <div className="col-span-2">
                        <h1 className="text-lg font-medium">Message</h1>
                        <textarea
                            className={`p-3 w-full font-light rounded-md focus:ring-blue-500 min-h-32 ${errors.message ? "border-red-500" : ""}`}
                            name="message"
                            placeholder="Chat with us—why are you the one for this job?"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                        {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
                    </div>
                    <div className="">
                        <div>
                            <h1 className="text-lg font-medium">Upload CV</h1>
                            <input
                                className={`w-full p-2 rounded-md cursor-pointer ${errors.file ? "border-red-500" : ""}`}
                                type="file"
                                name="file"
                                accept=".doc,.docx,.pdf"
                                onChange={handleChange}
                                placeholder="Upload your awesome CV here! (PDF, DOC, or DOCX)"
                            />
                            {errors.file && <p className="text-red-500 text-sm mt-1">{errors.file}</p>}
                        </div>
                    </div>
                    <div>
                        <ReCAPTCHA sitekey="6LdvzvsqAAAAAPOzRrBI_Uw6K44lIv3Bw-Ifr75b" onChange={(val) => setCaptcha(val)} />
                    </div>
                </div>

                <div className="w-full flex justify-center">
                    {loadingPost ? <DotLoading /> : <Button onClick={handleSubmit} status={captcha ? "primary" : "disable"} title="Submit" />}
                    {/* {captcha ? (
                        <Button onClick={handleSubmit} status={"primary"} title="Submit" />
                    ) : (
                        <Button onClick={handleSubmit} status={"disable"} title="Submit" />
                    )} */}
                </div>
            </form>
        </div>
    );
}

export default ApplyForm;
