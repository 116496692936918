import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";

export default function ListArticle({ article, handleReadMoreArticle, showButton }) {
    return (
        <div className=" grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-24 gap-10  px-10 ">
            {article.map((item, index) => (
                <div
                    key={index}
                    className="drop-shadow-2xl rounded-xl  overflow-hidden lg:h-[27rem] h-[25rem]  "
                    data-aos="zoom-in-up"
                    data-aos-easing="linear"
                >
                    <div className="relative overflow-hidden h-1/2 bg-black/40">
                        <a
                            className="absolute text-white border px-7 py-1 rounded-xl bg-white/10 backdrop-blur-sm z-10 hover:bg-white/35 transition ease-in-outs top-5 left-6"
                            href={item.link}
                        >
                            Article
                        </a>
                        <img
                            className="w-full h-full rounded-t-xl object-cover transform transition duration-500 hover:scale-110 hover:cursor-pointer"
                            src={`data:image/png;base64,${item.image}`}
                            onClick={() => handleReadMoreArticle(item)}
                            alt={item.image}
                        />
                    </div>
                    <div className="bg-white rounded-b-xl md:space-y-2 xl:px-5 px-2 py-3 lg:h-1/2 h-44 relative">
                        <div className="md:h-1/6 font-normal text-[#535353] text-sm py-2 ">
                            {item.dateText} | {item.timeRead}
                        </div>
                        <div className="md:h-1/6 font-normal text-[#535353] text-sm flex items-center space-x-3 ">
                            {item.tags.map((tag, index) => (
                                <p key={index} className="md:px-3 md:py-1 py-1 px bg-[#CCD6E4] rounded-full md:text-xs text-[px] text-[#003478]">
                                    {tag}
                                </p>
                            ))}
                        </div>
                        <div
                            className="md:h-3/6 title xl:text-lg lg:text-base md:text-sm text-base md:font-bold font-semibold text-[#676767]  hover:text-blue-500 hover:cursor-pointer"
                            onClick={() => handleReadMoreArticle(item)}
                        >
                            {item.title}
                        </div>
                        {/* <div className="flex justify-between items-center h-1/4"> */}
                        <div className="text-base xl:text-base lg:text-base md:text-sm md:font-medium text-[#1D4888] font-normal absolute bottom-4 right-4">
                            <button
                                onClick={() => handleReadMoreArticle(item)}
                                className={" px-0 text-[#003478] hover:text-blue-500 hover:cursor-pointer"}
                            >
                                Read More
                            </button>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            ))}
            {showButton === true && (
                <div className="flex justify-center items-center md:col-span-3 ">
                    <Link to={"/ourinsight"}>
                        <Button title="See Our Insight" status="primary" className="font-normal" />
                    </Link>
                </div>
            )}
        </div>
    );
}
