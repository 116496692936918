import React from "react";

const DownloadPDF = ({ fileData, fileName = "document.pdf" }) => {
    const handlePreview = () => {
        const byteCharacters = atob(fileData);
        const byteNumbers = new Uint8Array(byteCharacters.length).map((_, i) => byteCharacters.charCodeAt(i));
        const blob = new Blob([byteNumbers], { type: "application/pdf" });

        const url = URL.createObjectURL(blob);

        const newTab = window.open(url, "_blank");

        if (newTab) {
            const checkTabClosed = setInterval(() => {
                if (newTab.closed) {
                    clearInterval(checkTabClosed);
                    URL.revokeObjectURL(url);
                }
            }, 1000);
        }
    };

    return (
        <button
            onClick={handlePreview}
            className="bg-[#003478] text-white px-4 py-2 max-sm:px-3 max-md:w-full ease-in-out duration-300 hover:scale-105 text-center"
        >
            Preview PDF
        </button>
    );
};

export default DownloadPDF;
