import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
// import { articles } from "../../Data/data";

// Background
import ourInsight from "../../assets/ourInsight.png";

// content

import OurInsightNavbar from "./Section/OurInsightNavbar";
import Article from "./Section/Article";
import Paper from "./Section/Paper";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";

import AOS from "aos";
import useFetchApiGet from "../../Hooks/useFetchApiGet";
import FormatDateToText from "../../Constant/FormatDateToText";
import LazyLoad from "../../components/LazyLoadi";

export default function OurInsightContainer() {
    const [selectedFilterList, setSelectedFilterList] = useState({ id: 1, label: "Article" });

    const { data: articlePaper, setData: setListPaper, fetchApiGet: fetchApiGetPaper, loading: loadingArticle } = useFetchApiGet();
    const { data: articles, setData: setListArticle, fetchApiGet: fetchApiGetArticle, loading: loadingPaper } = useFetchApiGet();

    const filterList = [
        { id: 1, label: "Article" },
        { id: 2, label: "Paper" },
    ];

    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "Our Insight", path: "/ourInsight" },
    ];

    const getReadTime = (summary) => {
        const plainText = summary
            .replace(/<[^>]+>/g, " ")
            .replace(/\s+/g, " ")
            .trim();
        const wordCount = plainText.split(" ").length;
        const wordsPerMinute = 225;
        const timeInMinutes = Math.ceil(wordCount / wordsPerMinute);
        return `${timeInMinutes} min read`;
    };
    const currentDate = new Date();

    const onGetPaper = async () => {
        try {
            const response = await fetchApiGetPaper(`/paper/list/1/100/all`);
            if (response.status === 200) {
                const paper = response?.data?.data
                    .map((item) => ({
                        ...item,
                        dateText: FormatDateToText(item.date),
                        parsedDate: new Date(item.date),
                    }))
                    .filter((item) => item.parsedDate.getTime() <= currentDate.getTime())
                    .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
                setListPaper(paper);
                console.log("paper", paper);
            }
        } catch (error) {
            console.error("Error fetching service data:", error);
        }
    };

    const onGetArticle = async () => {
        try {
            const response = await fetchApiGetArticle(`/article/list/1/100/all`);
            if (response.status === 200) {
                const article = response.data.data
                    .map((item) => ({
                        ...item,
                        dateText: FormatDateToText(item.date),
                        timeRead: getReadTime(item.summary),
                        parsedDate: new Date(item.date),
                    }))
                    .filter((item) => item.parsedDate.getTime() <= currentDate.getTime())
                    .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

                setListArticle(article);
            }
        } catch (error) {
            console.error(error.message);
        }
    };
    console.log("Artikel", articles);

    const navigate = useNavigate();

    const handleReadMoreArticle = (article) => {
        navigate(`/ourInsight/article/${article.id}`, { state: { article } });
    };
    const handleReadMorePaper = (article) => {
        navigate(`/ourinsight/paper/${article.id}`, { state: { article } });
    };

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
            offset: 150,
            easing: "ease-in-out",
        });
    }, []);
    useEffect(() => {
        onGetArticle();
        onGetPaper();
    }, []);

    return (
        <div className="h-full">
            <Header bgColor="bg-white" className="h-screen lg:h-[30rem] md:h-[20rem] relative overflow-hidden max-md:hidden" position="end">
                <div className="h-full w-full xl:max-w-[1560px] mx-auto">
                    <div className=" h-full xl:w-1/2 w-full z-30 xl:text-4xl lg:text-3xl md:text-2xl text-4xl">
                        <div
                            className="text-[#ffffff] xl:text-xl lg:text-lg md:text-base text-lg font-normal"
                            data-aos="fade-down"
                            data-aos-delay="100"
                        >
                            Our Insight
                        </div>
                        <div className="text-[#ffffff] space-x-2" data-aos="fade-left">
                            <span>Welcome to the CRI</span>
                            <span className="text-[#67B7FD]" data-aos="fade-down">
                                Reliability
                            </span>
                        </div>
                        <div className="text-[#67B7FD]" data-aos="fade-right">
                            Publication <span className="text-white">Portal</span>
                        </div>
                    </div>
                    <div className="absolute inset-0 bg-gradient-to-r from-[#0C2665] via-[#0b235b] to-[#0C2665]/50  from-[20%] via-[25%] to-[60%] -z-10"></div>

                    <img className="absolute -right-24 top-0  object-cover -z-50 h-full " src={ourInsight} alt="" />
                </div>
            </Header>
            <Header bgColor="bg-white" className="h-screen md:h-[30rem] relative overflow-hidden md:hidden" position="center">
                <div className="h-full w-full">
                    <div className=" h-full xl:w-1/2 w-full z-30 text-4xl">
                        <div className="text-[#ffffff] text-lg font-normal" data-aos="fade-down">
                            Our Insight
                        </div>
                        <div className="text-[#ffffff]" data-aos="fade-left">
                            Welcome to the CRI{" "}
                            <span className="text-[#67B7FD]" data-aos="fade-down">
                                Reliability
                            </span>
                        </div>
                        <div className="text-[#67B7FD]">
                            Publication <span className="text-white">Portal</span>
                        </div>
                    </div>
                    <div className="absolute inset-0 bg-gradient-to-r from-[#0C2665] via-[#0b235b] to-[#0C2665]/50  from-[20%] via-[25%] to-[60%] -z-10"></div>

                    <img className="absolute -right-24 top-0  object-cover -z-50 h-full " src={ourInsight} alt="" />
                </div>
            </Header>
            <Breadcrumbs breadcrumbs={breadcrumb} className={"bg-[#003478]"} />
            <main className="text-lg text-[#616161]">
                <section className="flex justify-center items-center bg-[#003478] text-white xl:px-32 px-5 py-10 md:space-x-20  ">
                    <div className="space-y-10 xl:text-base md:text-sm text-xs xl:font-normal font-light xl:w-[1560px] max-w-screen-xl mx-auto">
                        <div data-aos="fade-right">
                            In the ever-evolving field of Reliability Engineering, staying informed about the latest research and developments is
                            crucial. Our portal serves as a comprehensive resource, providing access to a wide array of international journals and
                            papers. Whether you are a seasoned professional or a curious newcomer, this platform is designed to enhance your
                            understanding and keep you abreast of cutting-edge advancements in Reliability Engineering.
                        </div>
                        <div data-aos="fade-left">
                            Reliability Engineering is the backbone of ensuring systems and products perform consistently and safely over time. By
                            exploring the publications available here, you will gain insights into innovative methodologies, case studies, and
                            theoretical advancements that drive the industry forward. Join us in our commitment to fostering a deeper knowledge and
                            appreciation of
                        </div>
                    </div>
                </section>
                <section className="text-white bg-[#F7FBFE] md:px-5 py-10 md:space-x-5 xl:w-[1560px] xl:max-w-screen-xl mx-auto h-full">
                    <OurInsightNavbar filterList={filterList} selectedFilterList={selectedFilterList} setSelectedFilterList={setSelectedFilterList} />
                    {selectedFilterList.id === 1 ? (
                        <LazyLoad>
                            <Article articles={articles} handleReadMore={handleReadMoreArticle} loading={loadingArticle} />
                        </LazyLoad>
                    ) : selectedFilterList.id === 2 ? (
                        <LazyLoad>
                            <Paper paper={articlePaper} handleReadMore={handleReadMorePaper} loading={loadingPaper} />
                        </LazyLoad>
                    ) : null}
                </section>
            </main>
        </div>
    );
}
