import React, { useState, useEffect, useRef } from "react";
import leftButtonIcon from "../assets/left-button.png";
import rightButtonIcon from "../assets/right-button.png";
import { useNavigate } from "react-router-dom";

export default function Carousel({
    children: slides,
    titles,
    description,
    tagline = [],
    autoSlide = false,
    autoSlideInterval = 7000,
    url = [],
    buttonText = [],
}) {
    const navigate = useNavigate();
    const [curr, setCurr] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startPos, setStartPos] = useState(0);
    const [translateX, setTranslateX] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const carouselRef = useRef(null);

    const handleNavigate = () => {
        if (url[curr]) {
            navigate(url[curr]); // Menggunakan URL sesuai dengan slide yang aktif
        }
    };

    const prev = () => setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1));
    const next = () => setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));

    useEffect(() => {
        if (!autoSlide) return;
        const slideInterval = setInterval(next, autoSlideInterval);
        return () => clearInterval(slideInterval);
    }, [autoSlide, autoSlideInterval]);

    return (
        <div
            ref={carouselRef}
            className="relative flex justify-center items-center overflow-hidden w-full md:h-full h-screen xl:rounded-[30px] md:rounded-3xl max-md:rounded-none"
        >
            <div
                className={`flex transition-transform ease-out duration-500 w-full h-full ${isTransitioning ? "transition-transform" : ""}`}
                style={{ transform: `translateX(${translateX - curr * 100}%)` }}
            >
                {slides.map((slide, i) => (
                    <div key={i} className="w-full flex-shrink-0 flex justify-center items-center">
                        {slide}
                    </div>
                ))}
            </div>

            <div className="absolute xl:bottom-10 lg:bottom-6 md:bottom-3 xl:left-10 lg:left-7 md:left-8 left-0 flex flex-col justify-center items-center text-center z-20 md:px-0 px-5">
                <div className="w-full xl:px-5 text-start">
                    {tagline.length > curr && tagline[curr] && (
                        <div className="text-white lg:text-base md:text-xs text-sm font-normal p-2">{tagline[curr]}</div>
                    )}
                    {titles.length > curr && (
                        <div className="text-white lg:text-5xl md:text-3xl sm:text-2xl text-4xl font-semibold p-2 md:w-2/3">{titles[curr]}</div>
                    )}
                    {description.length > curr && (
                        <div className="text-white xl:text-lg lg:text-xs md:text-[10px] sm:text-sm text-base font-light p-2 md:w-2/3">
                            {description[curr]}
                        </div>
                    )}
                </div>

                <div className="w-full flex justify-start xl:px-5 md:px-2 px-2 py-5">
                    {url.length > curr && buttonText.length > curr && (
                        <button
                            className="xl:text-base lg:text-sm md:text-xs text-sm font-semibold text-[#003478] bg-white xl:px-3 xl:py-2 px-2 py-1 xl:rounded-xl rounded-md"
                            onClick={handleNavigate}
                        >
                            {buttonText[curr]}
                        </button>
                    )}
                </div>
            </div>

            <div className="absolute xl:bottom-5 xl:right-14 bottom-4 right-5 space-x-3 z-30 max-md:hidden">
                <button onClick={prev}>
                    <img className="w-8 h-8 md:w-7 md:h-7" src={leftButtonIcon} alt="Previous" />
                </button>
                <button onClick={next}>
                    <img className="w-8 h-8 md:w-7 md:h-7" src={rightButtonIcon} alt="Next" />
                </button>
            </div>

            <div className="absolute xl:bottom-24 xl:right-16 md:bottom-24 md:right-5 bottom-5 flex md:flex-col justify-center items-center md:space-y-5 max-md:space-x-5">
                {slides.map((_, i) => (
                    <div
                        key={i}
                        className={`md:w-3 md:h-3 sm:w-2 sm:h-2 w-3 h-3 rounded-full ${curr === i ? "bg-white" : "bg-gray-400 bg-opacity-50"}`}
                    />
                ))}
            </div>
        </div>
    );
}
