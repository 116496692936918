import { color, motion } from "framer-motion";
import React from "react";

const LoadingDot = {
    // display: "block",
    width: "0.9rem",
    height: "0.9rem",
    backgroundColor: "#003478",
    borderRadius: "50%",
    color: "#ffffff",
};

const LoadingContainer = {
    width: "5rem",
    // height: "5rem",
    display: "flex",
    justifyContent: "space-around",
};

const ContainerVariants = {
    initial: {
        transition: {
            staggerChildren: 0.2,
        },
    },
    animate: {
        transition: {
            staggerChildren: 0.2,
        },
    },
};

const DotVariants = {
    initial: {
        y: "0%",
    },
    animate: {
        y: "100%",
    },
};

const DotTransition = {
    duration: 0.5,
    repeat: Infinity,
    repeatType: "reverse",
    ease: "easeInOut",
};

export default function DotLoading() {
    return (
        <div
            style={{
                paddingTop: "5rem",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <motion.div style={LoadingContainer} variants={ContainerVariants} initial="initial" animate="animate">
                <motion.span style={LoadingDot} variants={DotVariants} transition={DotTransition} />
                <motion.span style={LoadingDot} variants={DotVariants} transition={DotTransition} />
                <motion.span style={LoadingDot} variants={DotVariants} transition={DotTransition} />
            </motion.div>
        </div>
    );
}
