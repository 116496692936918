import React, { useState } from "react";

export default function Paper({ paper, handleReadMore, loading }) {
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const handleCheckboxChange = (tag) => {
        setSelectedTags((prevSelectedTags) => {
            if (prevSelectedTags.includes(tag)) {
                return prevSelectedTags.filter((t) => t !== tag);
            } else {
                return [...prevSelectedTags, tag];
            }
        });
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const allTags = paper
        .map((item) => item.tags)
        .flat()
        .filter((value, index, self) => self.indexOf(value) === index);

    const filteredPaper = paper.filter((data) => {
        const matchesTags = selectedTags.length === 0 || data.tags.some((tag) => selectedTags.includes(tag));

        const matchesSearchQuery = data.title.toLowerCase().includes(searchQuery) || data.author.toLowerCase().includes(searchQuery);

        return matchesTags && matchesSearchQuery;
    });

    return (
        <div className="text-black h-full ">
            <div className="space-y-4 md:px-0 px-5">
                <h1 className="font-semibold text-[1.75rem]">Paper</h1>
                <p className="text-[#757575] xl:text-xl lg:text-lg md:text-base text-sm font-normal ">
                    We serve best insight for your industries and learn more about asset management solutions.
                </p>
            </div>
            {loading ? (
                <div className="text-center text-gray-400 text-xl font-medium h-[27rem] flex justify-center items-center animate-pulse duration-700 ease-in">
                    Loading content...
                </div>
            ) : paper.length === 0 ? (
                <div
                    className="text-center text-gray-400 text-xl font-medium h-[27rem] flex justify-center items-center animate-pulse duration-700 ease-in"
                    data-aos="fade-up"
                >
                    We're working behind the scenes to bring you great content. Check back soon!
                </div>
            ) : (
                <div className="flex max-md:flex-col justify-start gap-6 w-full h-full text-black max-md:px-5">
                    {/* tags */}
                    <div className="lg:w-1/6 lg:flex-col flex justify-start items-start h-full w-full">
                        <div className="max-md:flex max-md:justify-center max-md:items-center md:px-0 md py-10 px-5">
                            <h1 className="max-md:hidden">Tags</h1>
                            <input
                                type="search"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="mb-4 p-2 border border-gray-500/20 rounded-md text-sm font-light focus:border-gray-500/40"
                                placeholder="Search papers..."
                            />
                            {/* Dropdown for mobile */}
                            <div className="lg:hidden">
                                <select
                                    value={selectedTags}
                                    onChange={(e) => handleCheckboxChange(e.target.value)}
                                    className="mb-4 p-2 border border-gray-500/20 rounded-md text-sm font-light focus:border-gray-500/40 w-full"
                                >
                                    {allTags.map((tag, index) => (
                                        <option key={index} value={tag}>
                                            {tag}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* Checkbox list for larger screens */}
                            <div className="hidden lg:flex h-full lg:flex-col lg:justify-start lg:space-y-2 space-x-4 lg:space-x-0 lg:items-start justify-center items-center md:overflow-x-auto overflow-x-scroll w-full">
                                {allTags.map((tag, index) => (
                                    <div key={index} className="flex items-center space-x-3">
                                        <input
                                            type="checkbox"
                                            id={tag}
                                            className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                            checked={selectedTags.includes(tag)}
                                            onChange={() => handleCheckboxChange(tag)}
                                        />
                                        <label htmlFor={tag} className="text-gray-700 font-light">
                                            {tag}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* content */}
                    <div className="md:flex-1 bg-white w-full space-y-4 md:p-2 max-md:px-3">
                        {filteredPaper.length === 0 && paper.length !== 0 ? (
                            <div className="text-center text-gray-400 text-xl font-medium h-[27rem] flex justify-center items-center animate-pulse duration-700 ease-in">
                                No content available for the selected tags or search query.
                            </div>
                        ) : (
                            <div className="space-y-5">
                                {filteredPaper.map((data, index) => (
                                    <div
                                        key={index}
                                        className="w-full shadow-xl rounded-xl hover:cursor-pointer ease-in-out duration-300 hover:translate-x-2 "
                                        onClick={() => handleReadMore(data)}
                                    >
                                        <div className="p-5 md:text-lg md:space-y-8 space-y-3">
                                            <div className="space space-y-2">
                                                <h1 className="text-2xl md:text-3xl">{data.title}</h1>
                                                <div className="flex space-x-6">
                                                    <p className="md:text-sm text-xs font-light">
                                                        <span>Date: </span>
                                                        {data.dateText}
                                                    </p>
                                                    <p className="md:text-sm text-xs font-light">
                                                        <span>ISBN: </span>
                                                        {data.isbn}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="relative md:text-sm text-xs text-left font-light md:space-x-4 md:flex  items-center">
                                                <p>Tags:</p>
                                                <div className="md:flex md:flex-wrap max-md:grid max-md:grid-cols-2 text-center md:justify-start md:space-x-2 max-md:gap-3">
                                                    {data.tags.map((tag, index) => (
                                                        <p
                                                            key={index}
                                                            className="md:px-3 md:py-1 py-1 px bg-[#CCD6E4] rounded-full md:text-xs text-[px] text-[#003478]"
                                                        >
                                                            {tag}
                                                        </p>
                                                    ))}
                                                </div>
                                                <div className="absolute right-0 bottom-0 text-[#003478] font-semibold">Read More</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
