import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/Header";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AOS from "aos";
import useFetchApiGet from "../../../Hooks/useFetchApiGet";
import FormatDateToText from "../../../Constant/FormatDateToText";
import "quill/dist/quill.snow.css";
import ListArticle from "../../../components/ListArticle";
import { FaDownload } from "react-icons/fa";
import useFetchApiDownload from "../../../Hooks/useFetchApiDownload";
import { PrimaryButton, Spinner } from "@react-pdf-viewer/core";
import toast, { Toaster } from "react-hot-toast";
import Toast from "../../../components/Toast";
import Button from "../../../components/Button";

export default function ArticleDetail() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState([]);

    const { data: articleDetail, setData: setArticleDetail, fetchApiGet: fetchApiGetArticleDetail } = useFetchApiGet();
    const { data: otherArticles, setData: setOtherArticles, fetchApiGet: fetchApiGetArticle } = useFetchApiGet();
    const { loading: loadignDOwnload, fetchApiDownload: fetchApiDownload } = useFetchApiDownload();

    const getReadTime = (summary) => {
        const plainText = summary
            .replace(/<[^>]+>/g, " ")
            .replace(/\s+/g, " ")
            .trim();
        const wordCount = plainText.split(" ").length;
        const wordsPerMinute = 225;
        const timeInMinutes = Math.ceil(wordCount / wordsPerMinute);
        return `${timeInMinutes} min read`;
    };

    const onGetArticleDetail = async (id) => {
        try {
            const response = await fetchApiGetArticleDetail(`/article/detail/${id}`);
            if (response.status === 200) {
                const articleData = response.data.data;

                const formattedArticleData = {
                    ...articleData,
                    dateText: FormatDateToText(articleData.date),
                };

                setArticleDetail(formattedArticleData);

                const getReadTime = (summary) => {
                    const wordCount = summary.join(" ").split(" ").length;
                    const wordsPerMinute = 225;
                    const timeInMinutes = Math.ceil(wordCount / wordsPerMinute);
                    return `${timeInMinutes} min read`;
                };
                const allArticlesResponse = await fetchApiGetArticle(`/article/list/1/100/all`);
                if (allArticlesResponse.status === 200) {
                    const allArticles = allArticlesResponse.data.data;
                    const currentDate = new Date();

                    const filteredOtherArticles = allArticles
                        .filter((article) => article.id !== id)
                        .map((article) => ({
                            ...article,
                            timeRead: getReadTime(article.summary),
                            dateText: FormatDateToText(article.date),
                            parsedDate: new Date(article.date),
                        }))
                        .filter((item) => item.parsedDate.getTime() <= currentDate.getTime())
                        .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

                    setOtherArticles(filteredOtherArticles);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    console.log("asd", articleDetail.tags);
    const onGetOtherArticle = async (id) => {
        try {
            // const response = await fetchApiGetArticleDetail(`/article/detail/${id}`);
            const allArticlesResponse = await fetchApiGetArticle(`/article/list/1/100/all`);
            if (allArticlesResponse.status === 200) {
                const allArticles = allArticlesResponse.data.data;
                const currentDate = new Date();

                const filteredOtherArticles = allArticles
                    .filter((article) => article.id !== id)
                    .map((article) => ({
                        ...article,
                        timeRead: getReadTime(article.summary),
                        dateText: FormatDateToText(article.date),
                        parsedDate: new Date(article.date),
                    }))
                    .filter((item) => item.parsedDate.getTime() <= currentDate.getTime())
                    .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

                setOtherArticles(filteredOtherArticles);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const selectedFiles = articleDetail.files;

    const onDownloadArticle = async (file) => {
        try {
            let options = {
                responseType: "blob",
            };
            // const response = fetchApiDownloadAsset(`/Asset/export?FullName=${globalUser.fullname}`, options, "Export Asset Register.xlsx");
            const encodedFile = encodeURIComponent(file);
            const response = fetchApiDownload(`/global/download/${encodedFile}`, options, `${file.split("\\").pop()}`);
            toast.loading("Downloading...");
            if (response.status === 200) {
                toast.success("Downloaded");
            }

            console.log("file path nbanget", file);
        } catch (error) {
            console.log(error);
        }
    };
    const handleReadMore = (article) => {
        navigate(`/ourInsight/article/${article.id}`, { state: { article } });
    };

    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "Our Insight", path: "/ourInsight" },
        { label: articleDetail.title, path: `/ourInsight/article/${id}` },
    ];
    
    useEffect(() => {
        AOS.init({
            duration: 800,
            once: true,
            offset: 150,
            easing: "ease-in-out",
        });
    }, []);
    useEffect(() => {
        onGetArticleDetail(id);
    }, [id]);
    useEffect(() => {
        onGetOtherArticle();
    }, []);

    return (
        <div className="h-full w-full overflow-hidden">
            <Header
                bgColor="bg-gradient-to-r from-[#073067] to-[#1E6BCF] overflow-hidden"
                className="md:h-[6rem] max-md:h-[5rem] xl:px-32 md:px-14 px-10 text-white hover:text-blue-400 "
            />

            <div className="relative flex mx-auto flex-col lg:w-2/3 items-center xl:px-32  md:py-20 p-5 font-normal bg-[#F6FBFF]">
                <Breadcrumbs breadcrumbs={breadcrumb} />
                <Toaster
                    position="top-right"
                    toastOptions={{
                        duration: 1000,
                        style: {
                            background: "#333",
                            color: "#fff",
                        },
                    }}
                />
                {articleDetail ? (
                    <div className="w-full text-[#676767] ">
                        <div className="header md:space-y-10 space-y-5 ">
                            <div>
                                <img
                                    className="w-full md:h-[400px] h-[200px] object-cover rounded-lg drop-shadow-lg"
                                    src={`data:image/png;base64,${articleDetail.image}`}
                                    alt=""
                                    data-aos="fade-down"
                                />
                            </div>
                            {/* <button onClick={() => onDownloadArticle(id)}>{downloadArticle?.data?.data?.files}</button> */}

                            <div className="font-normal space-y-5 text bg-[#fefefe] shadow-md drop-shadow-md px-5 py-10 md:rounded-xl">
                                <div className="">
                                    {articleDetail.title && articleDetail.title.length > 0 && (
                                        <h1 className="md:text-3xl font-semibold text-[#2a5c9e]" data-aos="fade-down">
                                            {articleDetail.title}
                                        </h1>
                                    )}
                                </div>
                                <div className="md:h-1/6 font-normal text-[#535353] text-sm flex items-center space-x-2 ">
                                    {/* {articleDetail.tags.map((tag, index) => (
                                        <p
                                            key={index}
                                            className="md:px-3 py-1 px-3 bg-[#CCD6E4] rounded-full md:text-sm text-xs text-[px] text-[#003478]"
                                        >
                                            {tag}
                                        </p>
                                    ))} */}
                                </div>
                                <div className="text-[#6c6c6c] " data-aos="fade-right">
                                    {articleDetail.dateText && (
                                        <div className="flex space-x-2">
                                            <p className="">Date :</p>
                                            <p className="">{articleDetail.dateText}</p>
                                        </div>
                                    )}
                                    {articleDetail.author && (
                                        <div className="flex space-x-2">
                                            <p className="">Author :</p>
                                            <p className="">{articleDetail.author}</p>
                                        </div>
                                    )}
                                    {articleDetail.timeRead && <p className="">{articleDetail.timeRead}</p>}
                                </div>
                                <main className="overflow-hidden">
                                    {articleDetail.summary && (
                                        <p
                                            style={{ padding: 0 }}
                                            className="ql-editor"
                                            data-aos="fade-left"
                                            dangerouslySetInnerHTML={{ __html: articleDetail.summary }}
                                        ></p>
                                    )}
                                </main>
                                {selectedFiles?.length > 0 && (
                                    <>
                                        <h1 className="font-bold">Files</h1>
                                        <div className="flex  items-center space-x-5">
                                            {selectedFiles?.map((file, index) => {
                                                return (
                                                    <div className="flex items-center space-x-5">
                                                        <Button status={"primary"} key={index} onClick={() => onDownloadArticle(file)}>
                                                            <div className="flex items-center space-x-5">
                                                                <FaDownload />
                                                                <h1>{file.split("\\").pop()}</h1>
                                                            </div>
                                                        </Button>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>Service Detail not found.</p>
                )}
            </div>
            <div className="w-full flex justify-center xl:px-32 px-5 bg-[#F6FBFF] py-10">
                <ListArticle article={otherArticles} handleReadMoreArticle={handleReadMore} />
            </div>
        </div>
    );
}
